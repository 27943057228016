import API from "../utils/api";

export const getOrdersApi = async (data) => {
    const response = await API.get('order/get-all',{params:data});
    return { response };
};

export const getCheckoutApi = async (data) => {
    const response = await API.get('order/checkout',{params:data});
    return { response };
};

export const getCheckoutDataApi = async (id) => {
    const response = await API.get(`order/checkout/${id}`);
    return { response };
}

export const showOrderApi = async (id,data) => {
    const response = await API.get(`order/${id}/show`,{params:data});
    return { response };
};

export const downloadPdfApi = async ( id,data)  => {
    const response = await API.get(`order/${id}/download/pdf`,{'content-type': 'application/pdf','responseType': 'blob',params:data});
    return { response };
};

export const downloadBulkPdfApi = async ( data)  => {
    const response = await API.get(`order/download-bulk/pdf`,{'content-type': 'application/pdf','responseType': 'blob',params:{id_array:data.ids,name:data.name}});
    return { response };
};

export const downloadInvoiceApi = async ( id,data)  => {
    const response = await API.get(`order/${id}/download/invoice`,{'content-type': 'application/pdf','responseType': 'blob',params:data});
    return { response };
};

export const getOrderSettingsApi = async () => {
    const response = await API.get('order/get-settings');
    return { response };
};

export const addReturnApi = async (data) => {
    const response = await API.post('order/return-items/add', data);
    return { response };
};

export const sendReturnItemShopifyApi = async (data) => {
    const response = await API.post('order/send-return-items-shopify', data);
    return { response };
};

export const createTrackingInvoiceApi = async (data) => {
    const response = await API.post('order/invoice-tracking/add', data);
    return { response };
};
export const addTrackingApi = async (data) => {
    const response = await API.post('order/tracking/add', data);
    return { response };
};

export const downloadTrackingInvoiceApi = async ( id)  => {
    const response = await API.get(`order/download/tracking/invoice/${id}`,{'content-type': 'application/pdf','responseType': 'blob',});
    return { response };
};

export const showTrackingInvoiceApi = async id => {
    const response = await API.get(`order/${id}/tracking/show`);
    return { response };
};

export const getTrackingItemApi = async id => {
    const response = await API.get(`order/tracking/item/${id}/show`);
    return { response };
};

export const getReturnsApi = async (data) => {
    const response = await API.get('order/return',{params:data});
    return { response };
};

export const showReturnApi = async (id,data) => {
    const response = await API.get(`order/return/${id}/show`,{params:data});
    return { response };
};

export const showReturnListApi = async id => {
    const response = await API.get(`order/return/${id}/list`);
    return { response };
};

export const searchProduct = async (data) => {
    const response = await API.get('order/draft/search-product',{params:data});
    return { response };
};

export const customerAutoSuggestionApi = async (text) => {
    const response = await API.get('order/draft/customer-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const showPendingReturnRequestListApi = async (data) => {
    const response = await API.get('order/return-request-get-all',{params:data});
    return { response };
};

export const showPendingReturnReturn = async (id, data) => {
    const response = await API.get(`order/return-request-items/${id}/show`,{params:data});
    return { response };
};

export const storePendingReturnRequest = async (data) => {
    const response = await API.post('order/return-request/update', data);
    return { response };
};

export const getDraftOrdersApi = async (data) => {
    const response = await API.get(`order/draft`,{
        params: data,
    });
    return { response };
};
export const addDraftOrderApi = async (data) => {
    const response = await API.post('order/draft', data);
    return { response };
};
export const getDraftOrderApi = async (id) => {
    const response = await API.get(`order/draft/`+id+'/edit');
    return { response };
};

export const updateDraftOrderApi = async (data, id) => {
    const response = await API.put(`order/draft/${id}`, data);
    return { response };
};

export const getOrderCommissionsApi = async (data) => {
    const response = await API.get('order/get-seller-admin-commission',{params:data});
    return { response };
};

export const generateOrderShippingDetails = async (data) => {
    const response = await API.get('order/export-shipping-data', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const generateVatDetails = async (data) => {
    const response = await API.get('order/export-vat-data', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportEvriDataApi = async (data) => {
    const response = await API.get('order/export-evri-tracking', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportNextDayEvriDataApi = async (data) => {
    const response = await API.get('order/export-evri-tracking', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportSekoApi = async (data) => {
    const response = await API.get('order/export-seko-dpd-tracking', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportVogaClosetApi = async (data) => {
    const response = await API.get('order/export-vogacloset-tracking', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};
export const exportVogaClosetNewApi = async (data) => {
    const response = await API.get('order/export-vogacloset-new-file', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};
export const exportVogaClosetDetailApi = async (data) => {
    const response = await API.get('order/export-vogacloset-detailed-file', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportVogaClosetImportApi = async (data) => {
    const response = await API.get('order/export-vogacloset-tracking-import', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const generateOrderPdfApi = async (data) => {
    const response = await API.get(`order/generate-order-pdf`, {'content-type': 'application/pdf','responseType': 'blob',params:{start_date:data.start_date,end_date:data.end_date,id_array:data.orderIdArray}});
    return { response };
};

export const updateDispatchOrderDataApi = async (data) => {
    const response = await API.post(`order/order-dispatch/update-dispatch-details`, data);
    return { response };
}

export const importDpdFileApi = async (data) => {
    const response = await API.post('order/dpd-tracking/update-dpd-tracking-details', data)
    return { response };
}

export const importEvriileApi = async (data) => {
    const response = await API.post('order/evri-tracking/update-evri-tracking-details', data)
    return { response };
}



export const updateDeliveryPartnerApi = async (data) => {
    const response = await API.post(`order/change-delivery-partners`, data);
    return { response };
}

export const getCurrentDeliveryPartnerApi = async (data) => {
    const response = await API.get(`order/change-delivery-partners`, {params:data});
    return { response };
}

export const updateImportOrdersApi = async data => {
    const response = await API.post('order/draft/import', data, {'content-type': 'multipart/form-data','responseType': 'json'});
    return { response };
};

export const partnerAutoSuggestionApi = async (text) => {
    const response = await API.get('partner/get-all-partners', {
    params: text,
});
return { response };
};

export const generateAllPickingTicketApi = async (data) => {
    const response = await API.get(`order/picking-ticket/download-bulk/pdf`,{'content-type': 'application/pdf','responseType': 'blob',params:data});
    return { response };
}

export const generateReturnDetails = async (data) => {
    const response = await API.get('order/return-csv', {params:data},{'content-type': 'application/pdf','responseType': 'blob'});
    return { response };
};

export const generateReturnGroupDetails = async (data) => {
    const response = await API.get('order/export-return-group-data', {params:data},{'content-type': 'application/pdf','responseType': 'blob'});
    return { response };
};

export const generatePartnerDetails = async (data,id) => {
    const response = await API.get(`order/draft/export-format/${id}`, {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const getGroupReturnApi = async (data)=>{
    const response= await API.get('order/order-return-details', {params:data});
    return {response}
};
export const getGroupReturnDetailApi = async (id, data)=>{
    const response= await API.get(`order/order-return-detail/${id}`, {params:data});
    return {response}
};

export const crateGroupReturnApi = async (data)=>{
    const response = await API.post('order/group-return', data)
    return {response}
};

export const getAllPendingOrdersApi = async (data) => {
    const response = await API.get('order/return-request', {params:data});
    return {response}
}

export const getPendingCancelApi = async (data)=>{
    const response= await API.get('order/get-all-cancel-order-request', {params:data});
    return {response}
};

export const getPendingCancelDetailsApi = async (id)=>{
    const response= await API.get(`order/get-selected-cancel-order-request/${id}`);
    return {response}
};

export const getPendingCancelStatusApi = async (id,status)=>{
    const response= await API.get(`order/cancel-order/${id}/${status}`);
    return {response}
};


export const generatePendingReturnDetails = async (data) => {
    const response = await API.get('order/pending-return-csv', {params:data},{'content-type': 'application/pdf','responseType': 'blob'});
    return { response };
};

export const getLatestPickingTicketApi = async (id)=>{
    const response= await API.get(`order/picking-ticket/latest`);
    return {response}
};

export const getCustomerAddressApi = async (data) => {
    const response = await API.get(`buyer-lead/get-customer-address/${data}`);
    return {response}
}

export const getCountryListApi = async (data) => {
    const response = await API.get(`order/draft/settings`);
    return {response}
}

export const getPickingTicketApi = async (data) => {
    const response = await API.get(`order/picking-ticket/get-all`,{
        params:data
    });
    console.log("service",data);
    return {response}
}

export const reassignShippingApi = async (data) => {
    const response = await API.post(`order/reassign-delivery-partners`, data);
    return {response}
}

export const orderRefundDataApi = async (id, data) => {
    const response = await API.get(`order/refund-details/${id}`);
    console.log("service 34",response);
    return {response}
}

export const reassignSellerApi = async (data) => {
    const response = await API.post(`order/regenerate-picking-ticket`, data);
    return {response}
}