import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import {setSkuDialog} from "../../../store/prodcutSlice";
import TagsInput from "react-tagsinput";
import {ModalFooter} from "react-bootstrap";
import CustomTagsInput from "../../../../../share-components/ui/customTagsInput";


const SkuDetails = (props) => {
    const {setValue, trigger, getValues, imageSelectOnClick, skuOnClick} = props;
    const dispatch = useDispatch();
    const productState = useSelector(({product}) => product.product.skuDialogProps);
    console.log(productState);
    const colourId = productState.data ? productState.data.colour_id : "";
    const rowIndex = productState.data ? productState.data.rowIndex : "";
    const colourName = productState.data ? productState.data.colour : "";
    const [sku, setSku] = useState([]);
    const [isChange, setIsChange] = useState(false);


    const closDialog = () => {
        dispatch(setSkuDialog({props: false}))
    }
    useEffect(() => {
        if (colourId) {
            let skuData = [];
            const colourData = getValues('color').find(x => x.id == colourId);
            if (getValues('selling_type').find(x => x.id === 1)) {
                getValues('size').map((item, index) => {
                    const sku = ((getValues('code') + "-" + colourData.name + "-" + item.name).toLocaleLowerCase()).replaceAll(' ', '');
                    skuData.push({sku: sku, colour: colourData.id, size: item.id});
                });
            }
            if (getValues('selling_type').find(x => x.id === 2)) {
                const sku = ((getValues('code') + "-" + colourData.name + "-pack").toLocaleLowerCase()).replaceAll(' ', '');
                skuData.push({sku: sku, colour: colourData.id, size: 1});
            }
            setSku(skuData);

        }

        console.log(getValues('bin_numbers'))

    }, [colourId]);

    const updateBinDetails = (sizeId, value, isBin) => {
        if (colourId) {
            const binNumbers = getValues('bin_numbers');
            const dataIndex = binNumbers.findIndex(x => x.size_id == sizeId && x.colour_id == colourId);
            if (dataIndex == -1) {
                let data = {
                    size_id: sizeId,
                    colour_id: colourId,
                    bin_number: isBin ? value : [],
                    gtn_number: !isBin ? value : ""
                };
                binNumbers.push(data)
            } else {
                if (isBin) {
                    binNumbers[dataIndex].bin_number = value;
                } else {
                    binNumbers[dataIndex].gtn_number = value;
                }
            }
            setValue('bin_numbers', binNumbers);
        }
    }

    const binValue = (item) => {
        ;
        const binValue = getValues('bin_numbers').find(
            binItem => (binItem.size_id == item.size) && (binItem.colour_id == colourId)
        );
        return binValue ? binValue.bin_number : [];
    }
    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="lg"    {...productState.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        {colourName + "'s sku"}
                    </ModalHeader>
                    <ModalBody>


                        <Row className="product-name mb-3">
                            <Col md={4} className="text-center">
                                <strong>SKU</strong>
                            </Col>
                            <Col md={4} className="text-center">
                                <strong>GTN Number</strong>
                            </Col>
                            <Col md={4} className="text-center">
                                <strong>BIN Number</strong>
                            </Col>
                        </Row>
                        {sku.map((item, i) =>
                            <Row key={i + "-" + colourId} className="mb-1">
                                <Col sm={12}>
                                    <div className="" style={{marginBottom: 10}}>
                                        <div className="media">
                                            <div className="media-body ml-3">

                                                <Row className="product-name">
                                                    <Col md={4}>
                                                        <h6 className="mt-2"
                                                            style={{fontSize: 14}}>{(item.sku).toUpperCase()}</h6>
                                                    </Col>
                                                    <Col md={4}>
                                                        <input name="name" defaultValue={getValues('bin_numbers').find(
                                                            binItem => (binItem.size_id == item.size) && (binItem.colour_id == colourId))?.gtn_number
                                                        } type="text" onChange={(event) => {
                                                            updateBinDetails(item.size, event.target.value, 0)
                                                        }} placeholder="GTIN #"

                                                               className={`form-control`}/>
                                                    </Col>
                                                    <Col md={4}>
                                                        <CustomTagsInput
                                                            value={isChange || !isChange ? binValue(item) : []}
                                                            tagChangeEvent={(value)=>{
                                                                updateBinDetails(item.size, value, 1)
                                                                setIsChange(!isChange)
                                                            }}
                                                        >
                                                        </CustomTagsInput>
                                         

                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )}


                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col md={12}>
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    skuOnClick(rowIndex, colourId, colourName);
                                    closDialog();
                                }}>Save
                                </button>
                            </Col>
                        </Row>


                    </ModalFooter>
                    <Row className=" mb-3 mt-3">
                        <Col md={12}>
                            <div className="text-center">
                                <span>{"Temporary save only! For permanent save, use the Publish or save button."}</span>
                            </div>
                        </Col>
                    </Row>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(SkuDetails, areEqual);